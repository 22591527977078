import React from 'react';
import PropTypes from 'prop-types';
import { PageSEO, useSEO } from '../components/SEO';
import Main from '../page-sections/_misc/CookiePolicy';

const CookiePolicy = ({ location }) => {
  //
  const { pathname } = location;
  const seo = useSEO(pathname);

  return (
    <article>
      <PageSEO
        title={seo.title}
        description={seo.description}
        pathname={pathname}
        featureImage="og-cookie-policy.jpg"
      />
      <Main />
    </article>
  );
};

export default CookiePolicy;

CookiePolicy.propTypes = {
  location: PropTypes.object.isRequired,
};
